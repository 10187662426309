import React from 'react';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { ProjectsPageFilter } from './types';
import theme from '../../theme/theme';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type Props = {
  active: ProjectsPageFilter;
  onClick: (filter: ProjectsPageFilter) => void;
};

export const ProjectsFilter = ({ active, onClick }: Props) => {
  const { t: translate } = useTranslation();
  const filters: ProjectsPageFilter[] = [
    `${translate?.('filterAll')}`,
    `${translate?.('filterStarted')}`,
    `${translate?.('filterReview')}`,
    `${translate?.('filterCompleted')}`,
  ];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  ); // Matches xs (mobile)

  return (
    <Box
      display={'flex'}
      sx={{
        width: isMobile ? 'inherent' : '100%',
        maxHeight: { md: 'auto' },
        alignItems: { xs: 'flex-start', xl: 'center' },
        flexDirection: { xs: 'column', md: 'auto' },
      }}
    >
      <Tabs
        value={filters.indexOf(active)} // Sets the active tab based on the current filter
        onChange={(_, newIndex) => onClick(filters[newIndex])} // Updates filter based on the tab clicked
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          width: '100%',
          minHeight: '40px', // Smaller height for tabs
          '& .MuiTabs-scrollButtons': {
            '&.Mui-disabled': {
              opacity: 0,
              // display: { sx: 'flex', lg: 'none' }, // Completely hide the arrows when inactive instead of opacity:0 and leaving empty space
            },
          },
          '& .MuiTabs-flexContainer': {
            alignItems: 'center',
          },

          '& .MuiTabs-indicator': {
            // left: 0, // Moves indicator to the top
            bottom: { xs: 0, md: 'inherent' }, // Unsets the default bottom position
            backgroundColor: theme.palette.primary.main, // Blue underline for active tab
          },
        }}
      >
        {filters.map((filter) => (
          <Tab
            key={filter}
            label={`${filter}`} // Shows label with count
            sx={{
              typography: 'button',
              textTransform: 'uppercase',
              fontWeight: filter === active ? 'bold' : 'normal',
              fontSize: '14px',
              padding: '6px 12px',
              color:
                filter === active
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary,
              '&.Mui-selected': {
                color: theme.palette.primary.main, // Highlight text color for active tab
              },
              width: { xs: '100%', md: 'auto' }, // Full width for mobile view
              textAlign: { xs: 'left', md: 'auto' },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
