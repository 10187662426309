import { FirstOption, Option, passwordStrength } from 'check-password-strength';

// Custom rules
const customOptions: [FirstOption<string>, ...Option<string>[]] = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 10,
  },
];

// Password strength evaluator function
export const passwordStrengthEvaluator = (
  password: string,
): 0 | 1 | 2 | 3 | 4 => {
  const strength = passwordStrength(password, customOptions);
  return strength.id as 0 | 1 | 2 | 3;
};
