import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Notification } from '../../../react-query-toolkit/state/types';
import { InformationIcon } from 'icons/information-icon';
import { AlertIcon } from 'icons/alert-icon';
import { EmergencyIcon } from 'icons/emergency-icon';
import { CalendarIcon } from 'pages/projects/project-card/calendar-icon';
import { useNotificationDataContext } from '../../../react-query-toolkit/state/notifications-context';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

interface NotificationCardProps {
  notification: Notification;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
}) => {
  const { t: translate } = useTranslation();
  const {
    deleteNotificationLoading,
    deleteNotificationError,
    deletingNotificationId,
    handleDeleteNotification,
  } = useNotificationDataContext();

  const notificationTitles: Record<string, string> = {
    note_duedateChangePS: translate?.('note_duedateChangePSText'),
    note_duedateChangeProject: translate?.('note_duedateChangeProjectText'),
    note_newtask: translate?.('note_newtaskText'),
    note_statusChangeProjectstep: translate?.(
      'note_statusChangeProjectstepText',
    ),
  };

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [deletingNotificationClicked, setDeletingNotificationClicked] =
    useState(false);
  const handleCloseDialog = () => setOpenErrorDialog(false);

  // Open error dialog when deleteNotificationError occurs for this notification
  useEffect(() => {
    if (deleteNotificationError && deletingNotificationId === notification.id) {
      setOpenErrorDialog(true);
    }
  }, [deleteNotificationError, deletingNotificationId, notification.id]);

  const handleRemoveNotification = () => {
    setDeletingNotificationClicked(true);
    handleDeleteNotification(notification.id);
  };

  // Determine the due date status and corresponding icon and label
  function getDueDateStatus(
    notificationDueDate: string,
  ): 'emergency' | 'alert' | 'info' {
    const currentDate = dayjs().startOf('day');
    const dueDate = dayjs(notificationDueDate).startOf('day');

    if (dueDate.isBefore(currentDate)) {
      return 'emergency';
    } else if (dueDate.diff(currentDate, 'day') <= 1) {
      return 'alert';
    } else {
      return 'info';
    }
  }

  const dueDateStatus = getDueDateStatus(notification.duedate);

  const icon = {
    info: <InformationIcon />,
    alert: <AlertIcon />,
    emergency: <EmergencyIcon />,
  }[dueDateStatus];

  const dueDateLabel = {
    info: dayjs(notification.duedate).format('MMMM D'),
    alert: translate?.('dueDateTomorrowText'),
    emergency: translate?.('dueDateYesterdayText'),
  }[dueDateStatus];

  const title =
    notificationTitles[notification.type] ||
    translate?.('notificationFallbackText');

  const description = (() => {
    switch (notification.type) {
      case 'note_duedateChangePS':
        return translate?.('notificationDueDateChangedPSDescriptionText', {
          name: notification.InterpolationValues.name,
        });
      case 'note_duedateChangeProject':
        return translate?.('notificationDueDateChangedProjectDescriptionText', {
          duedate: dayjs(notification.duedate).format('MMMM D'),
        });
      case 'note_newtask':
        return translate?.('notificationNewTaskDescriptionText', {
          name: notification.InterpolationValues.name,
        });
      case 'note_statusChangeProjectstep':
        return translate?.('notificationStatusChangedDescriptionText', {
          name: notification.InterpolationValues.name,
          status: notification.InterpolationValues.status,
        });
      default:
        return translate?.('notificationNoDetailsAvailableText');
    }
  })();

  const theme = useTheme();
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: { xs: 'auto', lg: '80%' },
          borderRadius: 3,
          display: 'flex',
          alignItems: 'flex-start',
          paddingX: 4,
          paddingY: 6,
          position: 'relative',
          marginBottom: 5,
        }}
      >
        <Box pr={5} alignItems={'center'} height={'100%'}>
          <Tooltip
            title={
              dueDateStatus.charAt(0).toUpperCase() + dueDateStatus.slice(1)
            }
          >
            <Badge>{icon}</Badge>
          </Tooltip>
        </Box>
        <CardContent sx={{ flexGrow: 1, padding: '0 !important' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <Box display="flex" alignItems={'center'}>
              <Typography pr={5} color={'textColor.main'} variant="h6">
                {title}
              </Typography>
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent={'center'}
                height={'fit-content'}
                sx={{
                  paddingX: 3,
                  paddingY: 2,
                  borderRadius: 1,
                  bgcolor:
                    dueDateStatus === 'alert'
                      ? 'warning.light'
                      : dueDateStatus === 'emergency'
                        ? 'error.light'
                        : 'bg.main',
                }}
              >
                <IconButton sx={{ p: '0 !important' }}>
                  <CalendarIcon
                    color={
                      dueDateStatus === 'emergency'
                        ? `${theme.palette.text.contrast}`
                        : `${theme.palette.text.primary}`
                    }
                  />
                </IconButton>
                <Typography
                  pl={2}
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'center'}
                  variant="caption"
                  color={
                    dueDateStatus === 'emergency'
                      ? 'text.contrast'
                      : 'text.main'
                  }
                >
                  {dueDateLabel}
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={handleRemoveNotification}
              sx={{
                color: 'neutral.main',
                '&:hover': { color: 'textColor.main' },
                p: '0 !important',
                m: '0 !important',
              }}
            >
              {deletingNotificationClicked &&
              deletingNotificationId === notification.id ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Close />
              )}
            </IconButton>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
          >
            <Link
              flex={1}
              href={notification.link}
              underline="hover"
              color="inherit"
              target="_blank"
            >
              <Typography variant="body3" color="neutral.main">
                {description}
              </Typography>
            </Link>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ marginTop: 2 }}
            >
              {translate?.('receivedNotificationText', {
                createdAt: dayjs(notification.created_at).format(
                  'MMM D [at] h:mma',
                ),
              })}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Error Dialog */}
      <Dialog
        open={openErrorDialog}
        onClose={handleCloseDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">
          {translate?.('errorDialogTitleText')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography id="error-dialog-description">
            {translate?.('errorDialogDescriptionText')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {translate?.('errorDialogCloseButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationCard;
