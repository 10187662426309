import styled from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';

export const ThankYouContainer = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 625px;

  border-radius: 4px;
  background: ${colors.white};

  /* Card Shadow */
  box-shadow: 2px 2px 2px 0 ${colors.cardShadow};

  @media screen and (max-width: ${breakpoints.sm}) {
    display: flex;
    min-width: 251px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

export const ThankYouTextTitle = styled.p`
  color: ${colors.secondaryBlue};
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.085px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.06px;
  }
`;
export const CardText = styled.p`
  color: ${colors.darkGrey};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 450px;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 15px;
  }
`;
