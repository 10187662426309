import React, { useState } from 'react';
import { ClientAccountInfo } from 'pages/request-account/components/client-account-info/client-account-info';
import { CreatePassword } from 'pages/request-account/components/create-password';
import { TellUsMore } from 'pages/request-account/components/tell-us-more/tell-us-more';
import { ServiceProfile } from 'pages/request-account/components/service-profile';
import { ReviewInfo } from 'pages/request-account/components/review-info';
import { ThankYouDisplay } from 'pages/request-account/components/thank-you-display';
import { CaptchaModal } from 'pages/request-account/components/recaptcha-modal';

export interface RequestInformationType {
  step: number;
  clientName: string;
  contactName: string;
  phone: string;
  email: string;
  country: string;
  state: string;
  region: string;
  city: string;
  address: string;
  organizationType: string;
  size: string;
  service: string;
  password: string;
  comment: string;
}

export function RequestAccount() {
  const [requestedInformation, setRequestedInformation] =
    useState<RequestInformationType>({
      step: 1,
      clientName: '',
      contactName: '',
      phone: '',
      email: '',
      country: '',
      state: '',
      region: '',
      city: '',
      address: '',
      organizationType: '',
      size: '',
      service: '',
      password: '',
      comment: '',
    });

  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);

  const onSubmitForm = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    return new Promise((resolve) => {
      setIsCaptchaOpen(true); // Open the captcha modal
      resolve();
    });
  };

  const handleCaptchaVerify = () => {
    // Proceed with form submission after captcha is verified
    alert(` clientName': ${requestedInformation.clientName},
      "contactName": ${requestedInformation.contactName}},
      "phone": ${requestedInformation.phone},
      'email': ${requestedInformation.email},
      'country': ${requestedInformation.country},
      'state': ${requestedInformation.state},
      'region': ${requestedInformation.region},
      'city': ${requestedInformation.city},
      'address': ${requestedInformation.address},
      'organizationType': ${requestedInformation.organizationType},
      'size': ${requestedInformation.size},
      'service': ${requestedInformation.service},
      'password': ${requestedInformation.password},
      'comment': ${requestedInformation.comment}`);
    nextStep();
  };

  const prevStep = () => {
    if (requestedInformation.step > 1) {
      setRequestedInformation({ ...requestedInformation, step: step - 1 });
    }
  };

  const nextStep = () => {
    setRequestedInformation((prev) => ({ ...prev, step: prev.step + 1 }));
  };

  const { step } = requestedInformation;

  return (
    <>
      <CaptchaModal
        open={isCaptchaOpen}
        onClose={() => setIsCaptchaOpen(false)}
        onVerify={handleCaptchaVerify}
      />
      {step === 1 && (
        <ClientAccountInfo
          nextStep={nextStep}
          requestedInformation={requestedInformation}
          setRequestedInformation={setRequestedInformation}
        />
      )}
      {step === 2 && (
        <CreatePassword
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <TellUsMore
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 4 && (
        <ServiceProfile
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 5 && (
        <ReviewInfo
          requestedInformation={requestedInformation}
          onSubmit={onSubmitForm}
          prevStep={prevStep}
        />
      )}
      {step === 6 && <ThankYouDisplay />}
    </>
  );
}
