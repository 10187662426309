import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material';
import { useNavAndSideBarContext } from '../state/context';
import { LanguageSelector } from './language-selector';
import useAuth from 'configs/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from 'icons/arrow-down';
import { LogoContainer } from 'components/nav-and-sidebar/nav-bar/components/logo-container';
import { NotificationsContainer } from 'components/nav-and-sidebar/nav-bar/components/notifications-container';
import { CalendarContainer } from 'components/nav-and-sidebar/nav-bar/components/calendar-container';
import { PageNameContainer } from 'components/nav-and-sidebar/nav-bar/components/page-name-container';
import { NotificationDataProvider } from '../../../react-query-toolkit/state/notifications-context';

type Props = {
  pageName: string;
};

export const NavBar: React.FC<Props> = ({ pageName }: Props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { showMenuBar, setShowMenuBar, showSideBar, setShowSideBar } =
    useNavAndSideBarContext();

  const handleBurgerClick = () => {
    setShowSideBar(!showSideBar);
    setShowMenuBar(false);
  };

  const handleMobileUserIconClick = () => {
    setShowMenuBar(!showMenuBar);
    setShowSideBar(false);
    setIsOpen(!isOpen); // Toggle isOpen state
  };

  const [givenName, setGivenName] = useState<string | null>(null);

  useEffect(() => {
    if (auth.user) {
      if (auth.user.attributes?.given_name) {
        setGivenName(auth.user.attributes.given_name);
      }
    }
  }, [auth.user?.attributes?.given_name]);

  const [isOpen, setIsOpen] = useState(false);

  const { t: translate } = useTranslation();
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ height: '60px', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        {auth.user ? (
          <IconButton
            edge="start"
            onClick={handleBurgerClick}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <PageNameContainer pageName={pageName} />
        <Box
          onClick={handleMobileUserIconClick}
          sx={{ display: { md: 'none' } }}
        >
          {auth.user && (
            <IconButton>
              <AccountCircle />
            </IconButton>
          )}
        </Box>
        {!auth.user && (
          <Box
            width={'100%'}
            justifyContent={'space-between'}
            sx={{
              display: {
                xs: 'flex',
              },
            }}
          >
            <LogoContainer onClick={() => navigate('/')} />
          </Box>
        )}
        <Box
          width={'100%'}
          justifyContent={'space-between'}
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          {auth.user?.attributes && (
            <>
              <NotificationDataProvider>
                <LogoContainer onClick={() => navigate('/')} />
                <Stack
                  spacing={3}
                  direction="row"
                  sx={{ alignItems: 'center' }}
                >
                  <CalendarContainer />
                  <NotificationsContainer />
                  <LanguageSelector />
                  <IconButton onClick={handleMobileUserIconClick} disableRipple>
                    <AccountCircle />
                    <Box
                      justifyContent={'center'}
                      pl={3}
                      sx={{ display: { lg: 'flex', sm: 'none' } }}
                    >
                      <Typography variant={'body1'}>
                        {translate?.('navBarGreeting')}, {givenName}
                      </Typography>
                      <Box display={'flex'} alignItems={'center'} pl={3}>
                        <ArrowDown
                          style={{
                            transition: 'transform 150ms ease-in-out',
                            transform: isOpen ? 'rotate(180deg)' : 'none',
                          }}
                        />
                      </Box>
                    </Box>
                  </IconButton>
                </Stack>
              </NotificationDataProvider>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
