import React from 'react';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import NotificationCard from 'pages/notifications/components/notifications-card';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useNotificationDataContext } from '../../react-query-toolkit/state/notifications-context';
import { Box } from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import CircularProgress from '@mui/material/CircularProgress';

export const Notifications: React.FC = () => {
  const { t: translate } = useTranslation();
  const {
    handleGetNotifications,
    notificationsFetchingError,
    notificationsListIsLoading,
    deleteNotificationLoading,
    deleteNotificationError,
  } = useNotificationDataContext();

  const notifications = handleGetNotifications() || [];

  return (
    <PageWrapper>
      <Container width="100%" margin="0">
        <Container width={'100%'} margin={'0'}>
          <PageTitle title={translate?.('headerNotifications')} />
        </Container>
        <Container
          justifyContent="center"
          width="100%"
          flexDirection="column"
          margin="20px 0 0 0"
        >
          {notificationsListIsLoading ? (
            <Box
              display={'flex'}
              width={'100%'}
              height={'30%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress size={50} />
            </Box>
          ) : notificationsFetchingError ? (
            <Box>Error loading notifications</Box>
          ) : (
            notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
              />
            ))
          )}
          {deleteNotificationLoading && <Box>Deleting notification...</Box>}
          {deleteNotificationError && <Box>Error deleting notification</Box>}
        </Container>
      </Container>
    </PageWrapper>
  );
};
