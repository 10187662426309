import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  useCreateSlMutation,
  useGetPlByClassQuery,
  useUpdateSlMutation,
} from '../services/ghgci-sfe';
import { PlEntities, SlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import useAuth from 'configs/AuthContext';
import {
  DateFormats,
  NumberFormats,
  TimeFormats,
} from 'pages/user-profile/components/types';

export interface BasicUserSettingsData {
  secondaryemail: string;
  phone: string;
  jobtitle: string;
  timezone: string;
  location: string;
  numberformat: NumberFormats;
  timeformat: TimeFormats;
  dateformat: DateFormats;
  language: string;
}

export interface ExtendedUserSettingsData extends BasicUserSettingsData {
  sk_id: string;
}

export interface UserSettingsDataTypeCreate extends BasicUserSettingsData {
  secondaryemail: string;
  phone: string;
  jobtitle: string;
  timezone: string;
  location: string;
  numberformat: NumberFormats;
  timeformat: TimeFormats;
  dateformat: DateFormats;
  language: string;
  sk_id: string;
  typename: 'settings';
}

type UserSettingsContextType = {
  userSettingsIsLoading: boolean;
  userSettingsFetchingError: FetchBaseQueryError | SerializedError | undefined;

  handleCreateUserSettings: (params: BasicUserSettingsData) => void;
  isCreateUserSettingsLoading: boolean;
  isCreateUserSettingsSuccess: boolean;
  userSettingsCreationError: FetchBaseQueryError | SerializedError | undefined;
  resetCreateUserSettingsQueryValues: () => void;

  handleUpdateUserSettings: (params: ExtendedUserSettingsData) => void;
  isUpdateUserSettingsLoading: boolean;
  isUpdateUserSettingsSuccess: boolean;
  userSettingsUpdateError: FetchBaseQueryError | SerializedError | undefined;
  resetUpdateTaskQueryValues: () => void;
};

const defaultUserSettingsContext: UserSettingsContextType = {
  userSettingsIsLoading: false,
  userSettingsFetchingError: {},
  handleCreateUserSettings: () => [],
  isCreateUserSettingsLoading: false,
  isCreateUserSettingsSuccess: false,
  userSettingsCreationError: {},
  resetCreateUserSettingsQueryValues: () => {},
  handleUpdateUserSettings: () => {},
  isUpdateUserSettingsLoading: false,
  isUpdateUserSettingsSuccess: false,
  userSettingsUpdateError: {},
  resetUpdateTaskQueryValues: () => {},
};

const DataContext = createContext(defaultUserSettingsContext);

export const UserSettingsDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const auth = useAuth();
  const ghgciId = useMemo(() => {
    return (
      auth.user?.getSignInUserSession()?.getIdToken()?.payload[
        'custom:ghgci_id'
      ] || null
    );
  }, [auth.user]);

  const [
    createUserSettings,
    {
      isLoading: isCreateUserSettingsLoading,
      isSuccess: isCreateUserSettingsSuccess,
      reset: resetCreateUserSettingsQueryValues,
      error: userSettingsCreationError,
    },
  ] = useCreateSlMutation();

  const handleCreateUserSettings = async (params: BasicUserSettingsData) => {
    const {
      secondaryemail,
      phone,
      jobtitle,
      timezone,
      location,
      numberformat,
      timeformat,
      dateformat,
      language,
    } = params;

    createUserSettings({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        typename: SlEntities.Settings,
        secondaryemail,
        phone,
        jobtitle,
        timezone,
        location,
        numberformat,
        timeformat,
        dateformat,
        language,
      },
    }).unwrap();
  };

  const { isLoading: userSettingsIsLoading, error: userSettingsFetchingError } =
    useGetPlByClassQuery({
      typename: PlEntities.User,
      id: ghgciId,
      classItem: SlEntities.Settings,
    });

  const [
    updateUserSettings,
    {
      isLoading: isUpdateUserSettingsLoading,
      error: userSettingsUpdateError,
      isSuccess: isUpdateUserSettingsSuccess,
      reset: resetUpdateTaskQueryValues,
    },
  ] = useUpdateSlMutation();

  const handleUpdateUserSettings = async (params: ExtendedUserSettingsData) => {
    const {
      secondaryemail,
      phone,
      jobtitle,
      timezone,
      location,
      numberformat,
      timeformat,
      dateformat,
      language,
      sk_id,
    } = params;
    updateUserSettings({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        typename: SlEntities.Settings,
        secondaryemail,
        phone,
        jobtitle,
        timezone,
        location,
        numberformat,
        timeformat,
        dateformat,
        language,
        id: sk_id,
      },
    }).unwrap();
  };

  return (
    <DataContext.Provider
      value={{
        userSettingsIsLoading,
        userSettingsFetchingError,

        handleCreateUserSettings,
        isCreateUserSettingsLoading,
        isCreateUserSettingsSuccess,
        userSettingsCreationError,
        resetCreateUserSettingsQueryValues,

        handleUpdateUserSettings,
        isUpdateUserSettingsLoading,
        isUpdateUserSettingsSuccess,
        userSettingsUpdateError,
        resetUpdateTaskQueryValues,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useUserSettingsDataContext = () => useContext(DataContext);
