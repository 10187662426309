import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RequestInformationType } from 'pages/request-account';
import { PageWrapper } from 'components';

export interface ClientAccountInfoProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export function ServiceProfile(props: ClientAccountInfoProps): JSX.Element {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const [error, setError] = useState(false);
  const { t: translate } = useTranslation();

  const serviceArr = [
    translate?.('serviceArrItem1'),
    translate?.('serviceArrItem2'),
    translate?.('serviceArrItem3'),
    translate?.('serviceArrItem4'),
    translate?.('serviceArrItem5'),
  ];

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequestedInformation({
      ...requestedInformation,
      service: serviceArr[Number(e.target.value)],
    });
    setError(false);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequestedInformation({
      ...requestedInformation,
      comment: e.target.value,
    });
  };

  const handleNext = () => {
    if (requestedInformation.service) {
      nextStep();
    } else {
      setError(true);
    }
  };

  const checkedIndex = serviceArr.indexOf(requestedInformation.service);

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Box maxWidth="582px" mx="auto">
            <Typography
              mb={7}
              display={'flex'}
              color="primary.main"
              textAlign="center"
              gutterBottom
              sx={(theme) => ({
                fontSize: {
                  xs: theme.typography.h5.fontSize,
                  md: theme.typography.h4.fontSize,
                },
              })}
            >
              {translate?.('serviceProfileTitle')}
            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={4}
              justifyContent="space-between"
            >
              {/* Radio Group Section */}
              <FormControl
                component="fieldset"
                error={error && !requestedInformation.service}
                sx={{
                  flex: 1,
                  margin: {
                    xs: '40px 0px 40px 0px !important',
                    md: '40px 20px 40px 20px !important',
                  },
                }}
              >
                <Typography variant="body2" mb={5} color="primary.main">
                  {translate?.('serviceProfileSubTitle')}
                </Typography>
                <RadioGroup
                  name="service"
                  value={checkedIndex}
                  onChange={handleRadioChange}
                  sx={{ mb: 5 }}
                >
                  {serviceArr.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="body3"
                          color={'textColorSecondary.secondary'}
                        >
                          {item}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                {error && !requestedInformation.service && (
                  <FormHelperText>
                    {translate?.('errorSelectionRequired')}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Comment Box */}
              <Box
                my={9}
                mx={6}
                sx={{
                  flex: 1,
                  margin: {
                    xs: '40px 0px 40px 0px !important',
                    md: '40px 20px 40px 20px !important',
                  },
                }}
              >
                <Typography
                  display={'flex'}
                  variant="body3"
                  mb={3}
                  color={'textColor.main'}
                >
                  {translate?.('serviceProfileCommentTitle')}
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  rows={5}
                  placeholder="Write your comments here"
                  value={requestedInformation.comment}
                  onChange={handleCommentChange}
                />
              </Box>
            </Stack>

            {/* Buttons */}
            <Stack
              direction="row"
              justifyContent="space-between"
              mt={4}
              mb={7}
              sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}
            >
              <Button
                variant="outlined"
                onClick={prevStep}
                sx={{ width: { xs: '100%', sm: '120px' } }}
              >
                {translate?.('backButton')}
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ width: { xs: '100%', sm: '120px' } }}
              >
                {translate?.('nextButton')}
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>
    </PageWrapper>
  );
}
