import React from 'react';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import {
  CardText,
  ThankYouContainer,
  ThankYouTextTitle,
} from 'pages/request-account/components/styled';
import { PageWrapper } from 'components';
import { Box } from '@mui/material';
export function ThankYouDisplay(): JSX.Element {
  const translation = useTranslation();
  const { t: translate } = translation;

  return (
    <PageWrapper>
      <Box
        display={'flex'}
        alignItems={'center'}
        height={'100%'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <Container flexDirection={'column'}>
          <ThankYouContainer>
            <ThankYouTextTitle>
              {translate?.('thankYouDisplayTitle')}
            </ThankYouTextTitle>
            <CardText>{translate?.('thankYouDisplayMessage')}</CardText>
          </ThankYouContainer>
        </Container>
      </Box>
    </PageWrapper>
  );
}
