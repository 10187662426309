import React from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { RequestInformationType } from 'pages/request-account';
import { PageWrapper } from 'components';

export interface ClientAccountInfoProps {
  requestedInformation: RequestInformationType;
  onSubmit: (e: React.SyntheticEvent) => Promise<void>;
  prevStep: () => void;
}

export function ReviewInfo(props: ClientAccountInfoProps): JSX.Element {
  const { requestedInformation, onSubmit, prevStep } = props;
  const { t: translate } = useTranslation();

  const tellUsMoreArr = [
    {
      answerKey: translate?.('typeOfOrganizationQuestion'),
      answer: requestedInformation.organizationType,
    },
    {
      answerKey: translate?.('sizeOfOrganizationQuestion'),
      answer: requestedInformation.size,
    },
  ];

  const serviceArr = [
    {
      answerKey: translate?.('serviceInterestQuestion'),
      answer: requestedInformation.service,
    },
  ];

  const clientInfoArr = [
    {
      answerKey: translate?.('requestAccountClient'),
      answer: requestedInformation.clientName,
    },
    {
      answerKey: translate?.('requestAccountPhone'),
      answer: requestedInformation.phone,
    },
    {
      answerKey: translate?.('requestAccountEmail'),
      answer: requestedInformation.email,
    },
    {
      answerKey: translate?.('requestAccountCountry'),
      answer: requestedInformation.country,
    },
    {
      answerKey: translate?.('requestAccountState'),
      answer: requestedInformation.state,
    },
    {
      answerKey: translate?.('requestAccountRegion'),
      answer: requestedInformation.region,
    },
    {
      answerKey: translate?.('requestAccountCity'),
      answer: requestedInformation.city,
    },
    {
      answerKey: translate?.('requestAccountAddress'),
      answer: requestedInformation.address,
    },
  ];

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Box maxWidth="582px" mx="auto">
            <form onSubmit={onSubmit}>
              {/* Title */}
              <Typography
                mb={7}
                display={'flex'}
                color="primary.main"
                textAlign="center"
                gutterBottom
                sx={(theme) => ({
                  fontSize: {
                    xs: theme.typography.h5.fontSize,
                    md: theme.typography.h4.fontSize,
                  },
                })}
              >
                {translate?.('reviewYourInfoTitle')}
              </Typography>

              {/* Information Rows */}
              <Grid container spacing={4}>
                {/* Left Column: Client Information */}
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    sx={{
                      flex: 1,
                      margin: {
                        xs: '40px 0px 40px 0px !important',
                        md: '40px 20px 40px 20px !important',
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="primary"
                      gutterBottom
                      mb={3}
                    >
                      {translate?.('reviewYourInfoClientInfoTitle')}
                    </Typography>
                    {clientInfoArr.map((infoItem, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center" // Ensures vertical alignment
                        mb={3}
                        gap={4} // Adds spacing between the Typography elements
                      >
                        <Typography
                          variant="body2"
                          color="primary.main"
                          fontWeight="bold"
                        >
                          {infoItem.answerKey}:
                        </Typography>
                        <Typography variant="body1">
                          {infoItem.answer}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                {/* Right Column: Tell Us More & Service Profile */}
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    sx={{
                      flex: 1,
                      margin: { xs: '40px 0', md: '40px 20px' },
                      display: 'flex',
                      flexDirection: 'column', // Parent enforces column layout
                      gap: 4,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        color="primary"
                        gutterBottom
                        mb={3}
                      >
                        {translate?.('tellUsMoreTitle')}
                      </Typography>
                      {tellUsMoreArr.map((infoItem, index) => (
                        <Box
                          key={index}
                          display="flex"
                          flexDirection="column"
                          mb={3}
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            fontWeight="bold"
                          >
                            {infoItem.answerKey}:
                          </Typography>
                          <Typography variant="body1">
                            {infoItem.answer}
                          </Typography>
                        </Box>
                      ))}
                    </Box>

                    <Box>
                      <Typography variant="body1" color="primary" gutterBottom>
                        {translate?.('serviceProfileTitle')}
                      </Typography>
                      {serviceArr.map((infoItem, index) => (
                        <Box
                          key={index}
                          display="flex"
                          flexDirection="column"
                          mb={3}
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            fontWeight="bold"
                          >
                            {infoItem.answerKey}:
                          </Typography>
                          <Typography variant="body1">
                            {infoItem.answer}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Buttons */}
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent="space-between"
                my={7}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={prevStep}
                  sx={{ width: { xs: '100%', sm: '120px' } }}
                >
                  {translate?.('backButton')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: { xs: '100%', sm: '120px' } }}
                >
                  {translate?.('submitButton')}
                </Button>
              </Stack>
            </form>
          </Box>
        </Container>
      </Box>
    </PageWrapper>
  );
}
