import React from 'react';
import { DesktopCalendar } from 'pages/calendar/components/desktop-calendar';
import { MobileCalendar } from 'pages/calendar/components/mobile-calendar';
import { useTaskDataContext } from '../../react-query-toolkit/state/tasks-context';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { Box } from '@mui/material';
import { Item, Project } from '../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import CircularProgress from '@mui/material/CircularProgress';

export function Calendar() {
  const { handleGetClients } = useUserDataContext();
  const clientData = handleGetClients() || [];
  const { handleGetTasks, tasksListIsLoading } = useTaskDataContext();
  const rawTaskData = handleGetTasks() || [];

  // We add the clientName in this step (we do not use it on the DB)
  const updatedData = formatTaskData(rawTaskData, clientData);

  const { handleGetUser, isGetUserLoading } = useUserDataContext();

  // Adjust rawUserData to be Item[] | null
  const rawUserData: Item[] | null = handleGetUser() || [];

  const userProjects: Project[] =
    rawUserData && Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];

  return (
    <>
      <PageWrapper pyValue={9} heightValue={'calc(100% - 80px)'}>
        <Box
          justifyContent={{ xs: 'inherent', md: 'center' }}
          width="100%"
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          overflow={'hidden'}
        >
          {tasksListIsLoading || isGetUserLoading ? (
            <Box
              display={'flex'}
              width={'100%'}
              height={'30%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress size={50} sx={{ paddingY: 4 }} />
            </Box>
          ) : (
            <>
              <DesktopCalendar
                rawTaskData={updatedData}
                userProjects={userProjects}
              />
              <MobileCalendar
                rawTaskData={updatedData}
                userProjects={userProjects}
              />
            </>
          )}
        </Box>
      </PageWrapper>
    </>
  );
}
