import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

interface CaptchaModalProps {
  open: boolean;
  onClose: () => void;
  onVerify: () => void;
}

export const CaptchaModal: React.FC<CaptchaModalProps> = ({
  open,
  onClose,
  onVerify,
}) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value: string | null) => {
    if (value) setCaptchaVerified(true);
  };

  const handleSubmit = () => {
    if (captchaVerified) {
      onVerify();
      onClose();
    } else {
      alert('Please complete the captcha before proceeding.');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="captcha-dialog-title"
      maxWidth="xs" // Limits the maximum width of the modal
      fullWidth // Ensures it takes up the full width up to the maxWidth
    >
      <DialogTitle id="captcha-dialog-title">Verify you are human</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centers the captcha horizontally
          alignItems: 'center', // Centers the captcha vertically
          flexDirection: 'column',
          padding: '16px',
        }}
      >
        <ReCAPTCHA
          sitekey="6LcAU6gqAAAAAGLPkhj1IgYBwuTeUBZOVDxO3B47" // Replace with your reCAPTCHA site key
          onChange={handleCaptchaChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!captchaVerified}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
