type ProjectStep = {
  sk_id: string;
  name: string;
  duedate: string;
  status: string;
  assignees: string[];
};

const stepOrderHierarchy = [
  'set-up',
  'data-collection',
  'research',
  'calculation',
  'calculation-review',
  'draft-report',
  'report-review',
  'client-review',
  'finalize-report',
  'close-project',
];

export function getCurrentProjectStep(
  projectStepUpdated: string,
  projectStepNewStatus: string,
  projectStepsData: ProjectStep[],
): string {
  // Update the projectStepsData array with the latest status of the updated step
  const updatedProjectStepsData = projectStepsData.map((step) =>
    step.name === projectStepUpdated
      ? { ...step, status: projectStepNewStatus }
      : step,
  );

  // Loop through the hierarchy order and find the first step that is not "done"
  for (const stepName of stepOrderHierarchy) {
    const step = updatedProjectStepsData.find((s) => s.name === stepName);

    // Return the current step if it is not marked as "done"
    if (step && step.status !== 'done') {
      return step.name;
    }
  }

  // If all steps are "done" according to the hierarchy, return the last step
  return stepOrderHierarchy[stepOrderHierarchy.length - 1];
}
