import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { RequestInformationType } from 'pages/request-account';
import { passwordStrengthEvaluator } from 'utils/validations';
import { PageWrapper } from 'components';

export interface createPasswordProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export const CreatePassword = (props: createPasswordProps) => {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const { t: translate } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  // Define schema for validation
  const passwordSchema = yup.object().shape({
    password: yup
      .string()
      .required(translate('createPasswordErrorMessageRequired'))
      .min(8, translate('createPasswordErrorMessageMin8'))
      .matches(
        /(?=.*?[0-9])/,
        translate('createPasswordErrorMessageAtLeast1Number'),
      )
      .matches(
        /(?=.*?[A-Z])/,
        translate('createPasswordErrorMessageAtLeast1Uppercase'),
      )
      .matches(
        /(?=.*?[!@#$%^&*(),.?":{}|<>])/,
        translate('createPasswordErrorMessageAtLeast1SpecialChar'),
      ),
  });

  // Initialize form control with validation
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(passwordSchema),
  });

  useEffect(() => {
    setValue('password', requestedInformation.password);
  }, [requestedInformation, setValue]);

  // Handle the Next button action
  const handleNext = async () => {
    const isStepValid = await trigger();
    const updatedPasswordStrength = passwordStrengthEvaluator(
      requestedInformation.password,
    );
    if (
      isStepValid &&
      updatedPasswordStrength !== 0 &&
      updatedPasswordStrength !== 1 &&
      requestedInformation.password !== ''
    )
      nextStep();
  };

  // Toggle visibility of the password
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const [passwordStrength, setPasswordStrength] = useState<string>('');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const finalPasswordStrength = passwordStrengthConverter(
      passwordStrengthEvaluator(e.target.value),
    );
    setPasswordStrength(finalPasswordStrength);
  };
  const passwordStrengthConverter = (strengthNumber: number) => {
    switch (strengthNumber) {
      case 0:
        return translate?.('passwordStrengthEvaluatorWeak');
      case 1:
        return translate?.('passwordStrengthEvaluatorWeak');
      case 2:
        return translate?.('passwordStrengthEvaluatorMedium');
      case 3:
        return translate?.('passwordStrengthEvaluatorStrong');
      default:
        return translate?.('passwordStrengthEvaluatorWeak');
    }
  };

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Box maxWidth="434px" mx="auto">
            <Typography
              mb={7}
              color="primary.main"
              textAlign="center"
              gutterBottom
              sx={(theme) => ({
                fontSize: {
                  xs: theme.typography.h5.fontSize,
                  md: theme.typography.h4.fontSize,
                },
              })}
            >
              {translate('createPasswordTitle')}
            </Typography>
            <form>
              <Grid container spacing={3}>
                <Grid size={12}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={!!errors.password}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          {translate('createPasswordLabel')}
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setRequestedInformation({
                              ...requestedInformation,
                              password: e.target.value,
                            });
                            field.onChange(e);
                            handleChange(e);
                          }}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              sx={{ maxHeight: '1em' }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ color: 'inherit' }}
                              >
                                {showPassword ? (
                                  <VisibilityOff sx={{ fontSize: 24 }} />
                                ) : (
                                  <Visibility sx={{ fontSize: 24 }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label={translate('createPasswordLabel')}
                        />
                        {errors.password && (
                          <FormHelperText>
                            {errors.password.message}
                          </FormHelperText>
                        )}
                        {passwordStrength && (
                          <FormHelperText>
                            {' '}
                            {translate('createPasswordStrengthSubtitle')}{' '}
                            {passwordStrength}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid size={12}>
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={prevStep}
                      sx={{ width: '120px' }}
                    >
                      {translate('backButton')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      sx={{ width: '120px' }}
                    >
                      {translate('nextButton')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>
    </PageWrapper>
  );
};
