import React, { useEffect, useState } from 'react';
import { createClient, Entry } from 'contentful';
import { AccordionItem } from 'pages/faq/components/accordion-item';
import { FaqEntry } from 'pages/faq/components/types';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const client = createClient({
  space: 'y3n4tj2ong0l' as string,
  accessToken: 'whLrYVGlrSGjcMaHVmBpZKP7dIpLCRwt0jMiVHyCuqs' as string,
});

export const Faq: React.FC = () => {
  const [data, setData] = useState<Entry<FaqEntry, undefined, string>[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getEntries = async (contentType = 'faq') => {
      try {
        const response = await client.getEntries<FaqEntry>({
          content_type: contentType,
        });
        setData(response.items);
      } catch (error) {
        setError('There was an error getting data');
        console.error('Error fetching data from Contentful', error);
      }
    };

    getEntries();
  }, []);

  return (
    <div>
      {data.length > 0 ? (
        data.map((item) => (
          <AccordionItem
            key={item.sys.id}
            question={item.fields.question}
            answer={item.fields.answer}
          />
        ))
      ) : error ? (
        <p>{error}something</p>
      ) : (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={50} />
        </Box>
      )}
    </div>
  );
};
